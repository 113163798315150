import { IToastProps } from "@blueprintjs/core";
import { RetargetingSegment, Segment } from "@madhive/mad-sdk";

export const LAST_REFRESHED_STATUS_ACTIVE = "Active";

export const LAST_REFRESHED_STATUS_EXPIRED = "Expired";

export const GET_ALL_DMP_SEGMENTS_PENDING = "GET_ALL_DMP_SEGMENTS_PENDING";
export const GET_ALL_DMP_SEGMENTS_FAILURE = "GET_ALL_DMP_SEGMENTS_FAILURE";
export const GET_ALL_DMP_SEGMENTS_SUCCESS = "GET_ALL_DMP_SEGMENTS_SUCCESS";
export const GET_DMP_SEGMENT_PENDING = "GET_DMP_SEGMENT_PENDING";
export const GET_DMP_SEGMENT_FAILURE = "GET_DMP_SEGMENT_FAILURE";
export const GET_DMP_SEGMENT_SUCCESS = "GET_DMP_SEGMENT_SUCCESS";
export const GET_ARCHIVED_RETARGETING_SEGMENTS_SUCCESS =
  "GET_ARCHIVED_RETARGETING_SEGMENTS_SUCCESS";

export const ABORT_GET_ALL_DMP_SEGMENTS_REQUEST =
  "ABORT_GET_ALL_DMP_SEGMENTS_REQUEST";
export const RETARGETING_SEGMENT_CREATION_SUCCESS =
  "RETARGETING_SEGMENT_CREATION_SUCCESS";
export const RETARGETING_SEGMENT_ARCHIVED = "RETARGETING_SEGMENT_ARCHIVED";
export const BASE_SEGMENT_CREATION_SUCCESS = "BASE_SEGMENT_CREATION_SUCCESS";
export const UPDATE_SEGMENT_SUCCESS = "UPDATE_SEGMENT_SUCCESS";
export const UPDATE_SEGMENT_FAILURE = "UPDATE_SEGMENT_FAILURE";
export const UPDATE_SEGMENT_PENDING = "UPDATE_SEGMENT_PENDING";

export interface DmpSegmentsState {
  // Segments by ID including archived segments (Retargeting)
  readonly byId: Record<string, Segment>;
  readonly pendingById: Record<string, boolean>;
  readonly isOneLoading: boolean;
  readonly isAllLoading: boolean;
  readonly isArchivedLoaded: boolean;
  readonly receivedAllAt: null | number;
}

interface GetAllDmpSegmentsPendingAction {
  type: typeof GET_ALL_DMP_SEGMENTS_PENDING;
}

interface GetAllDmpSegmentsSuccessAction {
  type: typeof GET_ALL_DMP_SEGMENTS_SUCCESS;
  payload: Segment[];
  meta: {
    timestamp: number;
  };
}

interface GetAllDmpSegmentsFailureAction {
  type: typeof GET_ALL_DMP_SEGMENTS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetDmpSegmentPendingAction {
  type: typeof GET_DMP_SEGMENT_PENDING;
}

interface GetDmpSegmentSuccessAction {
  type: typeof GET_DMP_SEGMENT_SUCCESS;
  payload: Segment;
  meta: {
    timestamp: number;
  };
}

interface GetDmpSegmentFailureAction {
  type: typeof GET_DMP_SEGMENT_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface RetargetingSegmentCreationSuccessAction {
  type: typeof RETARGETING_SEGMENT_CREATION_SUCCESS;
  payload: RetargetingSegment;
}

interface UpdateSegmentSuccessAction {
  type: typeof UPDATE_SEGMENT_SUCCESS;
  payload: Segment;
  meta: {
    success: {
      message: string;
    } & IToastProps;
  };
}

interface UpdateSegmentPendingAction {
  type: typeof UPDATE_SEGMENT_PENDING;
  meta: {
    segment: Segment;
  };
}

interface UpdateSegmentFailureAction {
  type: typeof UPDATE_SEGMENT_FAILURE;
  meta: {
    segmentId: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

interface BaseSegmentCreationSuccessAction {
  type: typeof BASE_SEGMENT_CREATION_SUCCESS;
  payload: Segment;
}

interface RetargetingSegmentArchivedAction {
  type: typeof RETARGETING_SEGMENT_ARCHIVED;
  payload: string;
}

interface GetArchivedRetargetingSegmentsSuccessAction {
  type: typeof GET_ARCHIVED_RETARGETING_SEGMENTS_SUCCESS;
  payload: RetargetingSegment[];
}

export type DmpSegmentsActionTypes =
  | GetAllDmpSegmentsFailureAction
  | GetAllDmpSegmentsPendingAction
  | GetAllDmpSegmentsSuccessAction
  | GetDmpSegmentFailureAction
  | GetDmpSegmentPendingAction
  | GetDmpSegmentSuccessAction
  | UpdateSegmentSuccessAction
  | UpdateSegmentPendingAction
  | UpdateSegmentFailureAction
  | RetargetingSegmentArchivedAction
  | RetargetingSegmentCreationSuccessAction
  | BaseSegmentCreationSuccessAction
  | GetArchivedRetargetingSegmentsSuccessAction;

import { useEffect, useState } from "react";
import { Button, Icon, Select } from "..";
import { styles } from "./styles";
import { PagerProps } from "./types";
import { getMaxPage, getInitialPageSize } from "./utils";
import { PAGER_DEFAULT_PAGE, PAGER_DEFAULT_PAGE_SIZES } from "./constants";

/**
 * `Pager` is a component that allows the user to navigate through pages of data.
 * @param pagerProps: The Pager props.
 */
const Pager = ({
  count,
  page: _page = PAGER_DEFAULT_PAGE,
  pageSize: _pageSize,
  pageSizes = PAGER_DEFAULT_PAGE_SIZES,
  onPageChange,
  onPageSizeChange
}: PagerProps) => {
  const [page, setPage] = useState(_page);
  const [pageSize, setPageSize] = useState(
    getInitialPageSize(_pageSize, pageSizes)
  );
  const [maxPage, setMaxPage] = useState(getMaxPage(count, pageSize));
  const range = [
    page * pageSize + 1,
    Math.min(page * pageSize + pageSize, count)
  ];

  const handleSetPageSize = (newPageSize: number) => {
    const newMaxPage = getMaxPage(count, newPageSize);
    const newPage = Math.floor((page / maxPage) * newMaxPage);
    page !== newPage && setPage(newPage);

    setMaxPage(newMaxPage);
    setPageSize(newPageSize);
    onPageSizeChange?.(newPageSize);
  };

  const handleSetPage = (newPage: number) => {
    setPage(newPage);
    onPageChange?.(newPage);
  };

  // Update state if props change.
  useEffect(() => {
    !Number.isNaN(_page) && _page !== page && setPage(_page);
    _pageSize &&
      _pageSize !== pageSize &&
      setPageSize(getInitialPageSize(_pageSize, pageSizes));
  }, [_page, _pageSize]);

  return (
    <div className="kit-Pager" css={styles.layout}>
      <div css={styles.pageSize}>
        {pageSizes && pageSizes.length > 1 ? (
          <Select
            options={pageSizes.map(String)}
            selected={pageSize.toString()}
            onSelect={s => handleSetPageSize(parseInt(s, 10))}
            lookupId={n => n}
            lookupLabel={n => n}
            cannotClear
            flyoutProps={{
              position: "top-left"
            }}
          />
        ) : (
          <span>{pageSize}</span>
        )}
        Per Page
      </div>

      <div css={styles.paging}>
        <Button
          ariaLabel="previous page"
          variant="ghost"
          onClick={() => handleSetPage(page - 1)}
          disabled={!page}
        >
          <Icon name="Arrow" direction="left" />
        </Button>
        <span>
          {range[0]} - {range[1]}
        </span>
        of
        <span>{count}</span>
        <Button
          ariaLabel="next page"
          variant="ghost"
          onClick={() => handleSetPage(page + 1)}
          disabled={page === maxPage}
        >
          <Icon name="Arrow" direction="right" />
        </Button>
      </div>
    </div>
  );
};

export default Pager;
export * from "./types";

import { useMemo, useState } from "react";
import Pager from "..";
import { PagerHookData, PagerHookProps } from "../types";
import { PAGER_DEFAULT_PAGE, PAGER_DEFAULT_PAGE_SIZES } from "../constants";
import { getInitialPageSize } from "../utils";

/**
 * Hook that provides the Pager state variables along with the Pager.
 * @param pagerProps: The Pager props.
 */
export const usePager = <T,>(
  pagerProps: PagerHookProps<T>
): PagerHookData<T> => {
  const {
    data: _data,
    page: _page = PAGER_DEFAULT_PAGE,
    pageSize: _pageSize = PAGER_DEFAULT_PAGE_SIZES[0],
    pageSizes = PAGER_DEFAULT_PAGE_SIZES,
    onPageChange,
    onPageSizeChange
  } = pagerProps;
  const [page, setPage] = useState(_page);
  const [pageSize, setPageSize] = useState(
    getInitialPageSize(_pageSize, pageSizes)
  );
  const dataSlice = useMemo(
    () => _data?.slice(page * pageSize, page * pageSize + pageSize),
    [_data, page, pageSize]
  );

  return {
    page,
    pageSize,
    data: dataSlice,
    setPage,
    setPageSize,
    Pager: (
      <Pager
        {...pagerProps}
        page={page}
        pageSize={pageSize}
        onPageChange={page => {
          setPage(page);
          onPageChange?.(page);
        }}
        onPageSizeChange={pageSize => {
          setPageSize(pageSize);
          onPageSizeChange?.(pageSize);
        }}
      />
    )
  };
};
